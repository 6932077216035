import { Container, Heading, Text, Em } from '@chakra-ui/react';
import React from 'react';

export const PolicyParentPersonalDataPageBis: React.FC = () => {
  return (
    <Container alignItems={'center'} textAlign={'center'} py={'20'}>

      <Heading mt={'50'}>INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI </Heading>
      <Heading mt={'2'} mb={'20'}>in applicazione del Regolamento europeo n. 2016/679 e prestazione del consenso al trattamento </Heading>
      

      <Text mt={4} textAlign="justify">
      Egregio Signore, gentile Signora, <br/>
      nella Sua qualità di interessato/a, Le sottoponiamo la seguente informativa al fine del rilascio del Suo consenso al trattamento dei dati.
      <br/>L’informativa viene resa per adempiere agli obblighi imposti dal Regolamento europeo sulla protezione dei dati personali (Regolamento Europeo 2016/679).
      </Text>

      <Text mt={4} textAlign="justify"> Il <strong>Titolare del trattamento</strong>, cioè chi assume le decisioni in merito alle modalità e alle finalità del trattamento, è <strong>AIHEALTH srl</strong> con sede in Milano, via Santa Sofia n. 24, P.IVA 13640270966, nella persona del suo legale rappresentante <Em>pro tempore</Em> Di Trana Michele, tel. 3403857266, indirizzo e-mail: amministrazione@ai-health.it, PEC: ……………………………………………………….. .
      <br/>Il <strong>Responsabile della Protezione Dati </strong>(o DPO – <Em>Data Protection Officer</Em>)  è contattabile all’indirizzo e-mail: dpo@ai-health.it.
      </Text>

        <Text mt={4} textAlign="justify">
        I Suoi dati personali vengono raccolti dal Titolare in occasione dell’accesso e dell’utilizzo dell’applicazione <strong>KIDDOCARE</strong>, oltre che per consentirle l’utilizzo dell’applicativo per le seguenti finalità:
        <br/> - per fornirLe su Sua richiesta informazioni e notizie sui prodotti, sulle attività e sulle iniziative imprenditoriali di <strong>AIHEALTH srl</strong> mediante mail, telefono o chat;
        <br/> - per comunicazione commerciale e promozionale (<Em>marketing diretto</Em>), trattamento facoltativo che consente al Titolare il contatto diretto per comunicazioni promozionali e commerciali (es. newsletter) e l’invio di materiale informativo o il compimento di ricerche di mercato o sondaggi di opinione;
        <br/> - per analisi ed elaborazioni sui comportamenti (<Em>profilazione</Em>). Si tratta di trattamento facoltativo volto ad analizzare o prevedere gli interessi, le preferenze, il comportamento di consumo, l'ubicazione del cliente, per comporre categorie individuali o di gruppo, a livello statistico o di dettaglio finalizzato a migliorare la conoscenza dei bisogni e delle aspettative degli Interessati nei confronti del Titolare;
        <br/> - per comunicazione commerciale e promozionale dei dati verso società terze  per proprie attività di marketing diretto. Anche in questo caso si tratta di trattamento facoltativo.

        </Text>

        <Text mt={4} textAlign="justify">  I Suoi dati personali che saranno oggetto di trattamento per le finalità sopra riportate sono i seguenti:
        <br/> - Nome,<br/> - Cognome, <br/> - Luogo di nascita, <br/> - Data di nascita, <br/> - Foto dell'account <br/> - Carta di identità,<br/> - Tessera Sanitaria,<br/> - Indirizzo Email, <br/> - numero di Telefono,<br/> - Indirizzo di residenza, <br/> - Numero ed età dei figli ,  <br/>  - Frequenza e orari prevalenti di utilizzo dell'App,  
          <br/> - Modalità di utilizzo dell'App
        
        
        </Text>

        <Text mt={4} textAlign="justify"> I dati saranno trattati con modalità informatico-telematiche e archiviati dal Titolare per le finalità sopra indicate e in conformità alle modalità di trattamento previste dal Regolamento europeo sulla privacy.
      <br/>Specifiche misure di sicurezza sono adottate dal Titolare per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
      <br/>Tali operazioni di trattamento dei dati personali avvengono rispettando scrupolosamente i principi di riservatezza e di sicurezza richiesti dalla legge sopra richiamata ed ispirandosi ai principi di correttezza e liceità di trattamento.
        </Text>
        <Text mt={4} textAlign="justify"> Il conferimento dei dati e il consenso al relativo trattamento sono facoltativi, ma rappresentano condizioni necessarie per l’espletamento delle attività sopra descritte e l’eventuale rifiuto potrà comportare per <strong>AIHEALTH srl</strong> l’impossibilità di fornire i servizi qui descritti.
        </Text>
        <Text mt={4} textAlign="justify"> Nello specifico: 
        <br/> - il trattamento dei dati personali per finalità di comunicazioni informative, commerciali e promozionali, nonché per profilazione è lecito qualora l’Interessato abbia espresso il proprio preventivo e specifico consenso.
        <br/> - Il trattamento dei dati personali per comunicazioni commerciali e promozionali -marketing diretto - compiuto da società terze è lecito qualora l’Interessato abbia espresso il consenso esplicito.
        <br/>Inoltre, i dati personali degli Interessati potranno essere, altresì, trattati lecitamente per adempiere a obblighi previsti da leggi, regolamenti o normative sia nazionali che comunitarie o per rispondere ad istanze delle Autorità pubbliche e private legittimate. Ricorrendo queste ultime finalità la normativa applicabile non richiede un’esplicitazione del consenso dell’Interessato.

        </Text>

        <Text mt={4} textAlign="justify"> I Suoi dati personali verranno conservati da <strong>AIHEALTH S.r.l.</strong> fino al raggiungimento del quattordicesimo anno di età del minore interessato. Oltre tale data gli stessi potranno essere conservati solo in forma anonima.
        <br/>La informiamo che lei ha diritto di:
        <br/> - chiedere al Titolare l’accesso ai propri dati personali e conoscerne l’origine, le finalità e gli scopi del trattamento; 
        <br/> - chiedere al Titolare la rettifica o la cancellazione degli stessi o la limitazione del trattamento dei dati che la riguardato o di opporsi al loro trattamento, 
        <br/> - inoltre chiedere di trasmetterli a un altro Titolare del trattamento (portabilità dei dati);
        <br/> - chiedere di aggiornare o rettificare i suoi dati personali in modo che siano sempre accurati;
        chiedere di cancellare i suoi dati personali dalle banche dati e/o dagli archivi del titolare nel caso in cui non siano più necessari per le finalità indicate in precedenza;
        <br/> - revocare il consenso, in qualsiasi momento, laddove il trattamento dei suoi dati personali si basi sul consenso. Potrà revocare il Suo consenso inviando una comunicazione al Titolare. 
        <br/> - limitare il trattamento dei suoi dati personali in talune circostanze, ad esempio laddove ne abbia contestato l’esattezza, per il periodo necessario al Titolare del trattamento per verificarne l’accuratezza;
        <br/> - ottenere i suoi dati personali in formato digitale;
        <br/> - opporsi al trattamento dei suoi dati personali o richiedere di interromperlo per ciascuna delle finalità sopra indicate. A seguito di tale richiesta, il Titolare del trattamento non potrà più procedere al trattamento dei dati personali, fatti salvi i casi in cui le leggi ed i regolamenti lo consentano.
        <br/> - Con riferimento al diritto di opposizione al trattamento dei suoi dati per finalità di marketing, può sempre liberamente e gratuitamente segnalare al Titolare che desidera ricevere solo eventuali comunicazioni effettuate con modalità tradizionali (posta cartacea o chiamate con operatore).
        <br/> L’interessato ha inoltre il diritto di proporre reclamo al Garante per la protezione dei dati personali, in qualità di autorità di controllo, ai sensi dell’art. 77 del RGPD. In alternativa, può proporre reclamo all’Autorità Garante dello Stato dell’Unione Europea in cui risiede o abitualmente lavora oppure nel luogo dove si è verificata la presunta violazione
       </Text>
        <Text mt={4} textAlign="justify"> Il sottoscritto/La sottoscritta, ricevuta, ai sensi del Regolamento europeo per la privacy n. 2016/679,  l'informativa relativa ai trattamenti dei propri dati personali, consente il loro trattamento secondo le modalità e per le finalità indicate nell'informativa stessa.

        </Text>
        
    </Container>
  );
}