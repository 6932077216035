import { Box, Flex, Image } from "@chakra-ui/react";
import { PiCaretLeftDuotone, PiCaretRightDuotone } from 'react-icons/pi';

import { useState } from "react";
import { Button } from "./button";

export interface CarouselImage {
  imageUrl: string;
}

export interface CarouselProps {
  images: Array<CarouselImage>;
  width?: number;
  height?: number;
  startIndex?: number;
}

export const Carousel = ({ 
  images,
  width = 300,
  height = 600,
  startIndex
} : CarouselProps) => {

  const border: number = 0;
  const [currentImageIndex, setCurrentImageIndex] = useState(() => {
    return startIndex ? startIndex : Math.floor(images.length - images.length
    );
  });

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <Flex direction={'column'} alignItems="center">
      <Flex borderWidth={border} borderColor={'blue'} width={'100%'} alignItems={'center'} justifyContent={'center'} overflow={'hidden'} padding={'10'}>
        <Box position={'absolute'} zIndex={0} w={(width - 5) + 'px'} rounded={'4xl'} h={height + 'px'} transform={'scale(1.1)'} borderWidth={border}></Box>        
        <Box borderWidth={border}
          zIndex={0.5} 
          w={ width + 'px'} 
          alignItems={'center'} justifyContent={'center'}>
          <Flex justifyContent="center" 
            alignItems="center"
            borderWidth={border} borderColor={'green'}
            overflow={'visible'}
            width={ width * images.length + 'px'}
            transform={`translateX(-${currentImageIndex * 300}px)`}
            transition="transform 0.5s ease-in-out">
            {images.map((image, index) => (
              <Box key={index} 
                borderWidth={border} 
                w={ width } 
                h ={height }

                transform={ index === currentImageIndex ? 'scale(1.1)' : 'scale(0.9)'}
                position={ 'relative'}
                transition="transform 0.5s ease-in-out"
                ml={1} 
                mr={1} 
                overflow={'hidden'}>
                <Image src={image.imageUrl} w={'100%'} h={'100%'} alt={`carousel-image-${index}`} />
              </Box>
            ))}
          </Flex>
        </Box>
        {/*<Image src='/images/screens/cover.png' alt={`cover`} position={'absolute'} zIndex={2} w={(width + 5) + 'px'} transform={'scale(1.1)'} />
         */}
      </Flex>
      <Flex mt={4}>
        <Button onClick={handlePrev} mr={2}><PiCaretLeftDuotone /></Button>
        <Button onClick={handleNext}><PiCaretRightDuotone /></Button>
      </Flex>
    </Flex>
  );
};