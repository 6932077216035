import { Badge, Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components/ui/button';
import { Carousel, CarouselImage } from '../../components/ui/carousel';

const images: Array<CarouselImage> = [
  { imageUrl: '/images/screens/calendar.png' },
  { imageUrl: '/images/screens/home.png' },
  { imageUrl: '/images/screens/login.png' },
  { imageUrl: '/images/screens/consultations.png' },
  { imageUrl: '/images/screens/sos.png' },
];

export const HomePage: React.FC = () => {

  return (
    <Flex direction={'column'}>
      <Box py={'10'} bg={'primary.100'}>
        <Container textAlign={'center'}>
          <Heading size="7xl">Kiddocare</Heading>
          <Heading size="3xl">La linea diretta con il tuo pediatra</Heading>
        </Container>
      </Box>
      

      <Box p={{ base: '10', md: '40'}} bg={'secondary.100'}>
        <Container textAlign={'center'} marginBottom={'10'}>
          <Heading size="md">Come funziona</Heading>
          <Heading size="3xl">Un'APP molteplici funzionalità</Heading>
        </Container>

        <Flex gap={'10'} direction={{ base: 'column', md: 'row' }} justifyContent={'center'}>
          <Box rounded={'4xl'} bg={'primary.200'} padding={'10'} textAlign={'center'}>
            <Heading size="lg">Tutto quello che serve</Heading>
            <Text marginBottom={'10'}>Gestione dello studio, appuntamenti e richieste su un unico dispositivo</Text>
            <Image src={'/images/screens/home.png'} alt="Phone Icon" w={'300px'} marginX={'auto'} />
          </Box>
          <Box rounded={'4xl'} bg={'primary.200'} padding={'10'} textAlign={'center'}>
            <Heading size="lg">Assistant chat</Heading>
            <Text marginBottom={'10'}>Grazie all'AI potrai ottenere consulti più rapidi con uno screening preciso e veloce.</Text>
            <Image src={'/images/screens/consultations.png'} alt="Phone Icon" w={'300px'} marginX={'auto'} />
          </Box>
          <Box rounded={'4xl'} bg={'primary.200'} padding={'10'} textAlign={'center'}>
            <Heading size="lg">Gestione del paziente</Heading>
            <Text marginBottom={'10'}>Gestisci e visualizza ogni paziente direttamente dal tuo smartphone.</Text>
            <Image src={'/images/screens/clinicfolder.png'} alt="Phone Icon" w={'300px'} marginX={'auto'} />
          </Box>
          <Box rounded={'4xl'} bg={'primary.200'} padding={'10'} textAlign={'center'}>
            <Heading size="lg">Agenda smart</Heading>
            <Text marginBottom={'10'}>Ricevi prenotazioni, gestisci l'agenda e effettua pagamenti tutto in un'unica piattaforma.</Text>
            <Image src={'/images/screens/calendar.png'} alt="Phone Icon" w={'300px'} marginX={'auto'} />
          </Box>
          <Box rounded={'4xl'} bg={'primary.200'} padding={'10'} textAlign={'center'}>
            <Heading size="lg">Certificati e prescrizioni</Heading>
            <Text marginBottom={'10'}>Prescrivi in pochi click direttamente dal tuo smartphone.</Text>
            <Image src={'/images/screens/prescriptions.png'} alt="Phone Icon" w={'300px'} marginX={'auto'} />
          </Box>
        </Flex>
      </Box>

      <Box p={{ base: '2', md: '40'}} bg={'primary.700'} color={'white'}>
        <Container>
          <Flex gap={'10'} direction={{ base: 'column', md: 'row' }} justifyContent={'center'}>
            <Box textAlign={'center'} padding={'10'}>
              <Badge colorScheme="green" rounded={'4xl'} p={'3'} marginBottom={'10'}>
                <Heading size={'xl'}>LA NOSTRA MISSION</Heading>
              </Badge>
              <Heading size="2xl"><b>Innovare</b> il settore pediatrico, <b>semplificando la relazione</b> tra medico e paziente grazie alle nuove tecnologie, per rendere l'<b>assistenza sanitaria più fluida</b> ed efficace.</Heading>
            </Box>
            <Box textAlign={'center'} padding={'10'}>
              <Badge colorScheme="green" rounded={'4xl'} p={'3'} marginBottom={'10'}>
                <Heading size={'xl'}>LA NOSTRA VISION</Heading>
              </Badge>
              <Heading size="2xl">Consentire ai pediatri di <b>ottimizzare</b> il loro <b>tempo</b> e offrire ai genitori strumenti semplici per <b>monitorare</b> la salute dei loro figli, grazie a soluzioni <b>tecnologiche avanzate</b>.</Heading>
            </Box>
          </Flex>
        </Container>
      </Box>

      {/* 
      <Box p={'20'} py={'40'} bg={'background.100'} alignItems={'center'} justifyContent={'center'} textAlign={'center'}>
        <Button variant={'surface'} colorPalette={'primary'} margin={'5'} asChild>
          <Link to={'/payment/subscriptionTest'}>Test Subscription</Link>
        </Button>
        <Button variant={'solid'} colorPalette={'primary'} margin={'5'} asChild>
          <Link to={'/payment/subscription'}>Subscription</Link>
        </Button>
      </Box>
      */}
      <Box py={'20'} bg={'primary.100'} w="100%">
        <Carousel images={images}>
        </Carousel>
      </Box>
    </Flex>
  );
}
