import { Container, Heading, Text, Em } from '@chakra-ui/react';
import React from 'react';

export const PolicyPortalParentDataPage: React.FC = () => {
  return (
    <Container alignItems={'center'} textAlign={'center'} py={'20'}>

      <Heading mt={'50'}>INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI </Heading>
      <Heading mt={'2'} mb={'20'}>in applicazione del Regolamento europeo n. 2016/679 e prestazione del consenso al trattamento </Heading>
      
   
        <Text mb={4} textAlign="justify">
        Egregio Signore, gentile Signora,<br/>
        nella Sua qualità di interessato, Le sottoponiamo la seguente informativa al fine del rilascio del Suo consenso al trattamento dei dati.
        L’informativa viene resa per adempiere agli obblighi imposti dal Regolamento europeo sulla protezione dei dati personali (Regolamento Europeo 2016/679).
        </Text>

        <Text mb={4} textAlign="justify">
        Il <strong>Titolare del trattamento</strong>, cioè chi assume le decisioni in merito alle modalità e alle finalità del trattamento, è il <strong>medico pediatra</strong> da Lei scelto per il minore e a cui potrà collegarsi digitalmente mediante la piattaforma <strong>KIDDOCARE</strong>, fornita da <strong>AIHEALTH srl </strong>con sede in Milano, via Santa Sofia n. 24, P.IVA 13640270966, nella persona del suo legale rappresentante <Em>pro tempore</Em> Di Trana Michele, tel. 3403857266, indirizzo e-mail: amministrazione@ai-health.it, PEC: dpo@ai-health.it.

        </Text>
        <Text mb={4} textAlign="justify"> <strong>La presente informativa riguarda il trattamento dei Suoi seguenti dati personali:</strong>
         </Text>
          <Text mb={4} textAlign="justify">
         <strong> - Indirizzo mail;</strong>
        </Text> 
        <Text mb={4} textAlign="justify">    <strong> - Numero di cellulare.</strong>
        </Text> 
        <Text mb={4} textAlign="justify">
          <strong> Tali dati, previo suo consenso, verranno esclusivamente utilizzati per consentire al sistema KIDDOCARE di inviarle un link al sito tramite il quale potrà effettuare la pre-registrazione alla app KIDDOCARE e il relativo pagamento.
          Una volta effettuata la pre-registrazione al fine della installazione e dell’utilizzo della app le sarà sottoposta specifica informativa privacy con riguardo al trattamento dei dati effettuato mediante la app.
          </strong>
        </Text>  
        <Text mb={4} textAlign="justify">  Il conferimento dei dati e il consenso al relativo trattamento sono facoltativi, ma rappresentano condizioni necessarie per le operazioni di pre-registrazione e l’eventuale rifiuto potrà comportare l’impossibilità dell’installazione della applicazione  <strong> KIDDOCARE</strong>.
        </Text> 
        <Text mb={4} textAlign="justify">  Le operazioni di trattamento dei dati personali avvengono rispettando scrupolosamente i principi di riservatezza e di sicurezza richiesti dalla legge sopra richiamata ed ispirandosi ai principi di correttezza e liceità di trattamento.
          I sistemi informativi e i programmi informatici sono configurati riducendo al minimo l'utilizzazione dei dati personali.
          Specifiche misure di sicurezza sono adottate dal Titolare per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
          I dati saranno conservati per 12 mesi.

        </Text> 
        <Text mb={4} textAlign="justify">  Per il corretto funzionamento della piattaforma, i dati da Lei conferiti saranno comunicati responsabili del Titolare, quali:

        </Text> 
        <Text mb={4} textAlign="justify">  
          - Responsabili: <strong>AIHEALTH srl</strong> in quanto società fornitrice della piattaforma web e della applicazione <strong>KIDDOCARE</strong>, nonché i suoi fornitori e partner tecnologici;
          <br/> - gli amministratori di sistema e i fornitori dei servizi informatici, in qualità di responsabili del trattamento.
          <br/>  Resta fermo l'obbligo del Titolare di comunicare i dati ad Autorità Pubbliche su specifica richiesta.
        </Text> 
        <Text mb={4} textAlign="justify"> 

        </Text> 
        <Text mb={4} textAlign="justify">  La informiamo che lei ha diritto di:
        <br/>- chiedere al Titolare l’accesso ai propri dati personali, la rettifica o la cancellazione degli stessi o la limitazione del trattamento dei dati che la riguardato o di opporsi al loro trattamento, inoltre chiedere di trasmetterli a un altro Titolare del trattamento (portabilità dei dati);
        <br/>- revocare il consenso, in qualsiasi momento, laddove il trattamento dei suoi dati personali si basi sul consenso. Potrà revocare il Suo consenso inviando una comunicazione al Titolare. Si precisa che la mancanza del consenso al trattamento  comporterà l’impossibilità dell’installazione della applicazione KIDDOCARE;
        <br/>- proporre un reclamo all’Autorità di controllo ovvero l’autorità per la protezione dei dati personali nazionale o di qualunque altro paese dell’UE.

        </Text> 

      <Text mb={4} textAlign="justify">  I Suoi dati personali che saranno oggetto di trattamento per le finalità sopra riportate sono i seguenti:
      <br/>- Indirizzo email
      <br/>- N. di telefono

      
        </Text> 
        <Text mb={4} textAlign="justify"> <br/>Il sottoscritto/La sottoscritta, ricevuta, ai sensi del Regolamento europeo per la privacy n. 2016/679, l'informativa relativa ai trattamenti dei propri dati personali, consente il loro trattamento secondo le modalità e per le finalità indicate nell'informativa stessa.
        </Text> 



    </Container>
  );
}