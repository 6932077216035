import { 
  Box,
  Container,
  Heading,
  Image,
  Text,
  Flex,
  VStack,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { PricingTableStripe } from "../../components/stripe/pricing-table.stripe";

// Array per gestire le immagini
const consultImages = [
  { imageUrl: "/images/parents/chat1.png", alt: "Chat 1" },
  { imageUrl: "/images/parents/chat2.png", alt: "Chat 2" },
  { imageUrl: "/images/parents/videochiamata.png", alt: "Videochiamata" },
];

const requestImages = [
  { imageUrl: "/images/parents/home.png", alt: "Request 1" },
  { imageUrl: "/images/parents/certificati.png", alt: "Request 2" },
  { imageUrl: "/images/parents/prescrizioni.png", alt: "Request 3" },
];

const visitImages = [
  { imageUrl: "/images/parents/prenotavisita.png", alt: "Prenota visita" },
  { imageUrl: "/images/parents/visitaprenotata.png", alt: "Visita prenotata" },
];

const SOSImages = [
  { imageUrl: "/images/parents/SOS.png", alt: "SOS" },
  { imageUrl: "/images/parents/SOS2.png", alt: "SOS2" },
];

export const SubscriptionTest: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box bg="gray.50" py={6} position="relative">
      {/* Immagine decorativa */}
      <Image
        src={"/images/robot.png"}
        alt="Immagine decorativa in alto a destra"
        position="absolute"
        top="0"
        right="0"
        maxW="250px"
        objectFit="contain"
        zIndex={1}
        display={{ base: "none", md: "none", lg: "block" }} // Visibile solo su schermi grandi
      />
      <Container maxW="container.xl">
        {/* Testo principale */}
        <VStack textAlign="center" mb={6}>
          <Heading size="2xl" fontWeight="bold" color="teal.700" mb={3}>
            Buongiorno mamma e papà, io sono Kiddocare
          </Heading>
          <Text fontSize="lg" color="gray.700" maxW="800px" mb={4} lineHeight="1.6">
            Kiddocare è l'app che ti aiuta a gestire online la salute dei tuoi figli, <br />
            rimanendo sempre in contatto con il pediatra.
          </Text>
        </VStack>

        <VStack align="stretch">
          {/* Prima box */}
          <Flex
            direction={{ base: "column", md: "row" }}
            align="center"
            justify="flex-start"
            mb={{ base: 8, md: 6 }}
            pb={{ base: "1rem", md: "4rem" , lg: "4rem" }}
            
          >
            <Box flex="1" textAlign="right" maxW={{ base: "100%", md: "50%" }} mr={{ base: 0, md: 4 }} pb={3}>
              <Heading size="lg" fontWeight="bold" color="teal.600" mb={2}>
                Ti mostro come posso aiutarti ogni giorno
              </Heading>
              <Text fontSize="md" fontWeight="bold" color="gray.700" lineHeight="1.8">
                Questa è la mia pagina principale
              </Text>
              <Text fontSize="md" color="gray.700" lineHeight="1.8" mb={4}>
                Da qui potrai accedere a tutte le mie funzionalità
              </Text>
            </Box>
            <Image
              src={"/images/parents/home.png"}
              alt="Home con funzionalità accessibili"
              objectFit="contain"
              maxW={{ base: "200px", md: "180px", lg: "200px" }}
              borderRadius="md"
            />
          </Flex>

          {/* Seconda box con immagini */}
          <Flex
            direction={{ base: "column", md: "row-reverse" }}
            align="center"
            justify="flex-start"
            mb={{ base: 8, md: 6 }}
            pb={{ base: "1rem", md: "3rem" , lg: "2rem" }}
            
          >
            <Box flex="1" textAlign="left" maxW={{ base: "100%", md: "40%" }} ml={{ base: 0, md: 4 }} pt={6}>
              <Heading size="lg" fontWeight="bold" color="teal.600" mb={2}>
                Consulto rapido con il pediatra
              </Heading>
              <Text fontSize="md" color="gray.700" lineHeight="1.8" mb={4}>
                Possibilità di comunicare tramite chat dedicata, telefonata e videochiamate. <br />
                Meno spostamenti in ambulatorio.
              </Text>
            </Box>
            {isMobile ? (
              <Flex
                overflowX="auto"
                w="100%"
                mt={2}
                gap="2rem"
                px={6}
                pb={4}
                scrollSnapType="x mandatory"
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {consultImages.map((img, index) => (
                  <Box
                    key={index}
                    flex="0 0 auto"
                    scrollSnapAlign="center"
                    borderRadius="md"
                    overflow="hidden"
                  >
                    <Image
                      src={img.imageUrl}
                      alt={img.alt}
                      maxW="200px"
                      h="auto"
                      mx="auto"
                    />
                  </Box>
                ))}
              </Flex>
            ) : (
              <SimpleGrid columns={3} >
                {consultImages.map((img, index) => (
                  <Box key={index}>
                    <Image
                      src={img.imageUrl}
                      alt={img.alt}
                      maxW="180px"
                      h="auto"
                      mx={ {base:"1rem", md: 1 , lg: "2rem"}}
                    />
                  </Box>
                ))}
              </SimpleGrid>
            )}
          </Flex>

          {/* Terza box con immagini */}
          <Flex
            direction={{ base: "column", md: "row" }}
            align="center"
            justify="flex-start"
            mb={{ base: 8, md: 6 }}
            pb={{ base: "1rem", md: "3rem" , lg: "2rem" }}
            
          >
            <Box flex="1" textAlign="right" maxW={{ base: "100%", md: "50%" }} mr={{ base: 0, md: 4 }}>
              <Heading size="lg" fontWeight="bold" color="teal.600" mb={2}>
                Richieste automatiche rapide
              </Heading>
              <Text fontSize="md" color="gray.700" lineHeight="1.8" mb={4}>
                Ricette, certificati a portata di click. Prescrizioni disponibili a breve. <br />
                Ricevi i documenti direttamente in app.
              </Text>
            </Box>
            {isMobile ? (
              <Flex
                overflowX="auto"
                w="100%"
                mt={2}
                gap="2rem"
                px={6}
                pb={4}
                scrollSnapType="x mandatory"
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {requestImages.map((img, index) => (
                  <Box
                    key={index}
                    flex="0 0 auto"
                    scrollSnapAlign="center"
                    borderRadius="md"
                    overflow="hidden"
                  >
                    <Image
                      src={img.imageUrl}
                      alt={img.alt}
                      maxW="200px"
                      h="auto"
                      mx="auto"
                    />
                  </Box>
                ))}
              </Flex>
            ) : (
              <SimpleGrid columns={3} >
                {requestImages.map((img, index) => (
                  <Box key={index}>
                    <Image
                      src={img.imageUrl}
                      alt={img.alt}
                      maxW="180px"
                      h="auto"
                      mx={ {base:"1rem", md: 1 , lg: "2rem"}}
                    />
                  </Box>
                ))}
              </SimpleGrid>
            )}
          </Flex>
            {/* Quarta box con immagini legate alle visite */}
            <Flex
            direction={{ base: "column", md: "row-reverse" }}
            align="center"
            justify="flex-start"
            mb={{ base: 8, md: 6 }}
            pb={{ base: "1rem", md: "2rem" , lg: "0.7rem" }}
            
          >
            <Box flex="1" textAlign="left" maxW={{ base: "100%", md: "50%" }} ml={{ base: 0, md: 4 }}>
              <Heading size="lg" fontWeight="bold" color="teal.600" mb={2}>
                Gestione Calendario Visite
              </Heading>
              <Text fontSize="md" color="gray.700" lineHeight="1.8" mb={4}>
                Prenota visite, convenzionate o private, direttamente sull'app sia in remoto che in ambulatorio. <br />
                Disdici con un click.
              </Text>
            </Box>
            {isMobile ? (
              <Flex
                overflowX="auto"
                w="100%"
                mt={2}
                gap="2rem"
                px={6}
                pb={4}
                scrollSnapType="x mandatory"
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {visitImages.map((img, index) => (
                  <Box
                    key={index}
                    flex="0 0 auto"
                    scrollSnapAlign="center"
                    borderRadius="md"
                    overflow="hidden"
                  >
                    <Image
                      src={img.imageUrl}
                      alt={img.alt}
                      maxW="200px"
                      h="auto"
                      mx="auto"
                    />
                  </Box>
                ))}
              </Flex>
            ) : (
              <SimpleGrid columns={2} >
                {visitImages.map((img, index) => (
                  <Box key={index}>
                    <Image
                      src={img.imageUrl}
                      alt={img.alt}
                      maxW="180px"
                      h="auto"
                      mx={ {base:"1rem", md: 1 , lg: "2rem"}}
                    />
                  </Box>
                ))}
              </SimpleGrid>
            )}
          </Flex>

          {/* Box SOS */}
          <Flex
            direction={{ base: "column", md: "row" }}
            align="center"
            justify="flex-start"
            mb={{ base: 8, md: 6 }}
            pb={{ base: "1rem", md: "2rem" , lg: "2rem" }}
            
          >
            <Box flex="1" textAlign="right" maxW={{ base: "100%", md: "50%" }} ml={{ base: 0, md: 4 }} px ={3} pb={4} >
              <Heading size="lg" fontWeight="bold" color="teal.600" mb={2}>
                Tasto SOS, Emergenze
              </Heading>
              <Text fontSize="md" color="gray.700" lineHeight="1.8">
                Se il pediatra lo abilita, potrai richiedere un consulto istantaneo per gestire le emergenze.
              </Text>
            </Box>
            {isMobile ? (
              <Flex
                overflowX="auto"
                w="100%"
                mt={4}
                gap="2rem"
                px={6}
                scrollSnapType="x mandatory"
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {SOSImages.map((img, index) => (
                  <Box
                    key={index}
                    flex="0 0 auto"
                    scrollSnapAlign="center"
                    borderRadius="md"
                    overflow="hidden"
                  >
                    <Image
                      src={img.imageUrl}
                      alt={img.alt}
                      maxW="200px"
                      h="auto"
                      mx={ {base:"rem", md: 1 , lg: "2rem"}}
                    />
                  </Box>
                ))}
              </Flex>
            ) : (
              <SimpleGrid columns={2} >
                {SOSImages.map((img, index) => (
                  <Box key={index}>
                    <Image
                      src={img.imageUrl}
                      alt={img.alt}
                      maxW="180px"
                      h="auto"
                      mx={ {base:"1rem", md: 1 , lg: "2rem"}}
                      
                    />
                  </Box>
                ))}
              </SimpleGrid>
            )}
          </Flex>

          {/* Box Privacy */}
          <Flex
            direction="column"
            align="center"
            justify="center"
            mb={{ base: 8, md: 6 }}
          >
            <Box textAlign="center" maxW="800px" pb={6}>
              <Heading size="lg" fontWeight="bold" color="teal.600" mb={2}>
                Pagamenti sicuri e veloci e Privacy completa
              </Heading>
              <Text fontSize="md" color="gray.700" lineHeight="1.8">
                Garantiamo pagamenti sicuri direttamente in app e la protezione completa della tua privacy. <br />
                Nessun altro, se non te e il tuo pediatra, avrà accesso ai tuoi dati e le tue foto.
              </Text>
            </Box>
          </Flex>

          {/* Box finale prima del Pricing */}
          <Flex
            direction="column"
            align="center"
            justify="center"
            mb={{ base: 8, md: 6 }}
          >
            <Heading
              size="2xl"
              fontWeight="bold"
              color="teal.600"
              mb={2}
              textAlign={{ base: "center", md: "center" }}
              transition="transform 0.3s ease, color 0.3s ease"
              _hover={{
                transform: "scale(1.1)",
                color: "teal.800",
              }}
            >
              Semplifica la gestione della salute di tuo figlio <br /> Iscriviti ora su Kiddocare
            </Heading>
            <Text mt={3} fontSize="md" color="black" textAlign={{ base: "center", md: "left" }}>
              Per sostenere lo sviluppo e il continuo aggiornamento dell'app è previsto un contributo accessibile.
            </Text>
          </Flex>
        </VStack>

        <Box mt={6}>
          <PricingTableStripe />
        </Box>
      </Container>
    </Box>
  );
};
